import React, {Component} from 'react'
import {Helmet} from 'react-helmet'
import {Layout} from '../layouts'
import {Typography} from '../components'
import {colorAccent, largeSpacing} from '../theme'

class NoRoute extends Component {
    render() {
        return (
            <Layout location={this.props.location}>
                <Helmet defer={false}>
                    <title>{`Page not found`}</title>
                </Helmet>
                <div className={`d-flex flex-column justify-content-center`} style={{minHeight: `60vh`}}>
                    <Typography
                        component={`h1`}
                        style={{
                            padding: largeSpacing,
                            textAlign: `center`,
                            fontWeight: `700`
                        }}
                        size={6}
                        color={colorAccent}
                        gutterBottom
                    >
                        404
                    </Typography>
                    <Typography
                        component={`p`}
                        style={{
                            padding: largeSpacing,
                            textAlign: `center`,
                            fontWeight: `600`
                        }}
                        size={2.5}
                        gutterBottom
                    >
                        We couldn't find the page you requested.
                    </Typography>
                </div>
            </Layout>
        )
    }
}

export default NoRoute

// export const pageQuery = graphql`
//   query {
//     allWordpressPage {
//       edges {
//         node {
//           id
//           title
//           excerpt
//           slug
//           date(formatString: "MMMM DD, YYYY")
//         }
//       }
//     }
//   }
// `;
